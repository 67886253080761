.contact-container {
  background: linear-gradient(-45deg, #6b6b7e, #1b1a2a, #51495b, #6b6b7e);
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;
  height: 100vh;
  width: 100%;
  letter-spacing: 0.3vw;
  font-family: 'Work Sans', sans-serif;
  padding: 6%; }

.resume {
  display: flex;
  justify-content: center;
  padding-top: 3%; }
  .resume button {
    color: white;
    border-radius: 15px;
    cursor: pointer;
    border: none;
    min-width: 20%;
    outline: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.45vw;
    padding: 8%;
    background: #121226;
    transform: scale(1);
    transition: 0.3s; }
  .resume button:hover {
    transform: scale(1.04);
    transition: 0.3s; }

.contact {
  overflow: hidden;
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .contact .contact-header {
    flex: 0.8;
    padding-top: 3%;
    display: flex;
    align-items: center;
    font-size: 3.8vw;
    color: #1b1a2a;
    text-shadow: 2px 2px #6b6b7e;
    letter-spacing: 0.2em; }
  .contact .contact-icons {
    display: flex;
    flex-direction: column; }
    .contact .contact-icons .circles {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around; }
      .contact .contact-icons .circles img {
        width: 100%;
        height: auto;
        transform: scale(1);
        transition: 0.3s; }
      .contact .contact-icons .circles div, .contact .contact-icons .circles a {
        padding: 0 5% 2% 5%; }
      .contact .contact-icons .circles a > img {
        width: 100%;
        height: auto;
        cursor: pointer;
        height: auto;
        width: 100%;
        transform: scale(1);
        transition: 0.3s; }
      .contact .contact-icons .circles img:hover {
        transform: scale(1.04);
        transition: 0.3s; }
  .contact .contact-footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #6b6b7e; }
    .contact .contact-footer div {
      margin-top: 4%;
      font-size: 2vw; }
    .contact .contact-footer .larger-text {
      font-size: 2.6vw; }

@media (max-width: 600px) {
  .contact-container .contact {
    justify-content: space-around;
    overflow: scroll; }
    .contact-container .contact .contact-header {
      font-size: 40px;
      margin: 5% 0 0 0;
      flex: none; }
    .contact-container .contact .contact-icons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 6%; }
      .contact-container .contact .contact-icons .circles {
        flex-direction: column;
        align-items: center;
        flex: none;
        height: 50vh;
        position: relative;
        width: 45%; }
        .contact-container .contact .contact-icons .circles div, .contact-container .contact .contact-icons .circles a {
          padding: 2% 5% 2% 5%;
          position: relative; }
      .contact-container .contact .contact-icons .resume {
        margin-top: 4%;
        height: 10%;
        max-width: 40%; }
        .contact-container .contact .contact-icons .resume button {
          font-size: 10px;
          border-radius: 17px;
          padding: 15%; }
    .contact-container .contact .contact-footer {
      flex: none; }
      .contact-container .contact .contact-footer .larger-text {
        font-size: 25px; }
      .contact-container .contact .contact-footer div {
        font-size: 18px; } }
