.articles-container {
  min-height: 100vh;
  z-index: 4;
  cursor: default;
  width: 100%;
  background: linear-gradient(-45deg, #1b1a2a, #1b1a2a, #6b6b7e);
  background-size: 400% 400%;
  padding: 6% 6% 4% 6%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Work Sans', sans-serif;
  font-size: 1.8vw;
  align-items: center;
  animation: Gradient 15s ease infinite; }

a {
  text-decoration: underline;
  color: darkblue; }

@keyframes Gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

.articles-head {
  min-height: 20vh;
  width: 100%;
  color: white;
  background-image: linear-gradient(to bottom right, #6b6b7e, #4d4d5e, #6b6b7e);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3%; }
  .articles-head .title-text {
    font-size: 3.8vw;
    text-shadow: 2px 2px #6b6b7e;
    letter-spacing: 0.2em;
    margin-bottom: 3%; }
  .articles-head .subtitle-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6vw; }

.articles-list {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  width: 100%; }

.article {
  box-shadow: 10px 10px 3px 0px rgba(107, 107, 126, 0.678);
  background: white;
  margin-bottom: 3%;
  min-height: 10vh;
  padding: 0 2% 1% 2%;
  overflow-y: hidden;
  font-family: 'Open Sans', sans-serif;
  transform: scale(1);
  transition: 0.3s; }
  .article .article-head {
    width: 100%;
    margin-top: 5%;
    height: auto;
    display: flex;
    flex-direction: row;
    transition: 1s ease-in-out; }
    .article .article-head .picture-frame {
      overflow: hidden;
      height: 0;
      width: 20%;
      padding-bottom: 20%; }
      .article .article-head .picture-frame img {
        height: 14vw;
        position: relative; }
    .article .article-head .title-container {
      display: flex;
      max-width: 70%;
      justify-content: center;
      font-size: 1.4vw;
      align-items: center;
      margin: 0 auto;
      text-align: center; }
  .article .article-body {
    margin-top: 2%;
    height: 0;
    width: 100%;
    padding: 1%;
    transition: 1s ease-in-out;
    position: relative; }
    .article .article-body .article-text {
      min-height: 0vh;
      transform-origin: top;
      margin: 1% 6% 1% 6%;
      display: flex;
      flex-direction: column; }
      .article .article-body .article-text > div {
        width: 100%;
        font-size: 1.4vw;
        text-align: center;
        line-height: 140%; }
      .article .article-body .article-text .body-text {
        font-family: 'Open Sans', sans-serif;
        line-height: 29px;
        font-size: 19px;
        text-align: left; }
        .article .article-body .article-text .body-text p {
          margin: 25px 0;
          text-indent: 25px; }
        .article .article-body .article-text .body-text pre {
          background: #3f3f3f;
          color: white;
          font-family: monospace; }
      .article .article-body .article-text .centered {
        text-align: center; }
    .article .article-body .horizontal-line {
      background: #121226;
      width: 0;
      height: 3px;
      margin-bottom: 4%;
      transition: 1s ease-in-out; }
    .article .article-body .animate-line {
      width: 100%;
      transition: 1s ease-in-out; }
  .article .show {
    height: auto;
    transition: 1s ease-in-out; }

.article:hover {
  transform: scale(1.03);
  transition: 0.3s; }

.selected:hover {
  transform: scale(1);
  transition: 0s; }

@media (max-width: 600px) {
  .articles-container {
    padding: 5% 3%; }
  .articles-head {
    min-height: 14vh; }
    .articles-head .title-text {
      font-size: 40px; }
    .articles-head .subtitle-text {
      font-size: 18px; }
  .article {
    box-shadow: 4px 4px 3px 0px rgba(107, 107, 126, 0.678); }
    .article .article-head {
      flex-direction: column;
      justify-content: center; }
      .article .article-head .title-container {
        font-size: 16px; }
      .article .article-head .picture-frame {
        margin: 0 auto;
        position: relative;
        width: 80%;
        height: 120px;
        margin-bottom: 9px;
        display: flex;
        justify-content: center;
        align-items: flex-start; }
        .article .article-head .picture-frame img {
          height: 100%;
          position: absolute;
          margin: 0 auto;
          left: 0;
          right: 0; }
    .article .article-body .article-text .body-text {
      font-size: 13px; }
    .article .article-body .article-text div {
      font-size: 14px; } }
