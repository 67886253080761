.projects-container {
  min-height: 100vh;
  width: 100%;
  background: white;
  padding: 4% 6%; }

.projects-header {
  display: flex;
  flex-direction: column;
  font-family: 'Work Sans', sans-serif;
  color: #1b1a2a;
  font-size: 3.8vw;
  text-shadow: 2px 2px #6b6b7e;
  letter-spacing: 0.2em;
  text-align: center; }
  .projects-header > div:nth-child(1) {
    margin-top: 20px; }
  .projects-header span, .projects-header h2 {
    color: #4d4d5e;
    font-size: 50%;
    letter-spacing: 0.2em;
    text-shadow: none; }
  .projects-header span {
    margin-top: 1%; }
  .projects-header h2 {
    margin-top: 1.5%;
    font-size: 65%; }

.projects-body .project {
  background: #6b6b7e;
  background: linear-gradient(to bottom right, #5e5e6d, #6b6b7e, #7d7d91);
  box-shadow: 10px 10px 3px 0px rgba(158, 158, 177, 0.678);
  padding: 3% 3% 0 3%;
  margin: 4% auto;
  width: 90%;
  display: flex;
  flex-direction: column; }
  .projects-body .project .carousel-box {
    width: 60%;
    margin: 0 auto; }
  .projects-body .project .large {
    width: 20%;
    margin: 0 auto; }

.projects-body .project-info {
  width: 100%;
  margin: 2% auto;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .projects-body .project-info > div {
    margin-bottom: 3%; }
  .projects-body .project-info .project-title {
    font-family: 'Work Sans', sans-serif;
    font-size: 220%;
    text-align: center; }
  .projects-body .project-info .project-description {
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    font-size: 18px;
    line-height: 1.5em;
    padding: 0 5%; }
  .projects-body .project-info .expandable {
    width: 100%;
    padding: 0 7%; }
    .projects-body .project-info .expandable .arrow {
      text-align: center;
      cursor: pointer;
      font-size: 2vw;
      transform: rotateX(0);
      transition: 0.3s ease-in-out; }
    .projects-body .project-info .expandable .rotate {
      transform: rotateX(180deg);
      transition: 0.3s ease-in-out; }
    .projects-body .project-info .expandable .technologies {
      font-family: 'Open Sans', sans-serif;
      overflow: hidden;
      padding-top: 3%;
      text-align: center;
      margin-bottom: none !important;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .projects-body .project-info .expandable .technologies .technologies-header {
        font-size: 120%;
      font-weight: 600; }
      .projects-body .project-info .expandable .technologies .technologies-container {
        padding-top: 3%;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around; }
        .projects-body .project-info .expandable .technologies .technologies-container .tech {
          margin: 1.2% 0;
          min-width: 25%; }
  .projects-body .project-info .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around; }
    .projects-body .project-info .buttons a {
      text-decoration: none;
      color: inherit;
      font-family: 'Work Sans', sans-serif; }
      .projects-body .project-info .buttons a button {
        color: white;
        box-shadow: 3px 3px 3px 0px rgba(27, 26, 42, 0.678);
        cursor: pointer;
        border: none;
        min-width: 100%;
        outline: none;
        font-size: 100%;
        padding: 11%;
        background: #4d4d5e;
        transform: scale(1);
        transition: 0.3s; }
      .projects-body .project-info .buttons a button:hover {
        transform: scale(1.1);
        transition: 0.3s; }

@media (max-width: 600px) {
  .projects-container {
    padding: 4% 3%; }
    .projects-container .projects-header {
      text-shadow: 2px 2px #6b6b7e;
      font-size: 40px; }
      .projects-container .projects-header .title {
        margin: 10px 0; }
      .projects-container .projects-header h2, .projects-container .projects-header span {
        display: none; }
  .projects-body .project {
    box-shadow: 4px 4px 3px 0px rgba(158, 158, 177, 0.678);
    width: 100%; }
    .projects-body .project .carousel-box {
      width: 90%; }
    .projects-body .project .project-info .buttons a button {
      min-width: 75%;
      height: 100%;
      padding: 8%;
      font-size: 13px; }
    .projects-body .project .project-info .project-title {
      font-size: 25px; }
    .projects-body .project .project-info .project-description {
      font-size: 16px;
      line-height: 115%; }
    .projects-body .project .project-info .expandable .arrow {
      font-size: 20px; }
    .projects-body .project .project-info .expandable .technologies .technologies-header {
      font-size: 17px; }
    .projects-body .project .project-info .expandable .technologies .technologies-container .tech {
      margin: 2%;
      font-size: 14px; } }
