.home-upper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #5c5c5c;
  background-image: linear-gradient(to bottom right, #121226, #2d2d38);
  width: 85vw;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  position: absolute; }

.top-half {
  display: flex;
  flex-direction: row; }

.component-container {
  height: 100vh;
  width: 85vw;
  position: relative;
  left: 85vw;
  z-index: 3;
  overflow-y: auto;
  overflow-x: hidden;
  background: #2d2d38; }

.home-lower {
  height: 100vh;
  z-index: 3;
  width: 100%;
  overflow: hidden;
  padding: 0 5vw 3vw 5vw;
  overflow-y: auto;
  background: white; }

.statement-card {
  margin: 0;
  display: flex;
  z-index: 3;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.1em;
  height: 23vh;
  width: 40%;
  background: rgba(77, 77, 94, 0.774);
  box-shadow: 0px 0px 10px 10px rgba(28, 28, 39, 0.87);
  font-size: 300%;
  color: white; }

@keyframes fadeOut {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.flex-container {
  width: 100%;
  height: 100%;
  flex: 1; }
  .flex-container .title-text {
    margin: 3% 0 0 3%; }
    .flex-container .title-text img {
      width: 10%;
      height: auto;
      opacity: 0.1; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.flex-bottom-center {
  display: flex;
  justify-content: center;
  align-items: flex-end; }

.down-arrows {
  margin-bottom: 1%; }

.text {
  opacity: 1;
  transition: 0.5s ease-in-out;
  animation: fadeOut 2.5s infinite; }

.invisible {
  opacity: 0;
  transition: 0.4s;
  transition-timing-function: ease-in-out; }

.front {
  z-index: 4; }

.bottom {
  transform: translateY(-100vh);
  transition: 1.5s;
  transition-timing-function: cubic-bezier(1, 0.15, 1); }

.left {
  transition: 1.5s;
  transition-timing-function: cubic-bezier(1, 0.15, 1); }

.right {
  transform: translateX(-85vw);
  transition: 1.5s;
  transition-timing-function: cubic-bezier(1, 0.15, 1); }

.top {
  transition: 1.5s;
  transition-timing-function: cubic-bezier(1, 0.15, 1); }

.up-fast {
  transition: 0.7s ease-in-out;
  transition-timing-function: cubic-bezier(1, 0.15, 1); }

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 85vw;
  overflow: hidden; }

.arrows {
  width: 60px;
  height: 72px;
  margin-bottom: 6px; }
  .arrows path {
    stroke: #6b6b7e;
    fill: transparent;
    stroke-width: 2px;
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite; }

.zoom {
  text-align: center;
  color: #6b6b7e;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  transition: .2s;
  cursor: pointer; }

.zoom:hover {
  transform: scale(1.05);
  filter: brightness(1.5);
}

figure {
  width: 25%;
  height: 0;
  padding-bottom: 25%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 10px 5px rgba(27, 26, 42, 0.678); }
  figure img {
    width: 100%;
    height: auto;
    position: relative; }

.up-arrows {
  transition: 0.2s ease-in-out;
  width: 63px;
  margin: 15px auto 0px auto; }
  .up-arrows svg path {
    stroke: #121226; }

.short-profile {
  min-height: 27%;
  width: 100%;
  display: flex;
  padding: 3%;
  background: #4d4d5e;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'Work Sans', sans-serif;
  color: white;
  font-size: 3vw;
  letter-spacing: 0.2em; }
  .short-profile .profile-text {
    width: 50%;
    padding-left: 4%;
    display: flex;
    flex-direction: column; }
    .short-profile .profile-text h2 {
      text-align: center;
      font-size: 1.8vw;
      margin-top: 3%; }
    .short-profile .profile-text h1 {
      text-align: center; }

.profile-icons {
  margin-top: 8%;
  display: flex;
  justify-content: space-around; }
  .profile-icons a img {
    width: auto;
    height: 3vw; }

.horizontal-divider {
  background: black;
  margin-top: 3%;
  width: 0%;
  height: 3px;
  transition: 1.5s ease-out; }

.animate-length {
  width: 100%;
  transition: 1.5s ease-out; }

.vertical-divider {
  min-height: 100%;
  width: 3px;
  background: black; }

.about-lower {
  position: relative;
  min-height: 56vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.info-panel {
  padding: 3%;
  width: 47%;
  font-family: 'Open Sans', sans-serif; }
  .info-panel h3 {
    font-size: 3.7vw;
    color: #121226;
    margin-bottom: 4%;
    text-align: center;
    font-family: 'Work Sans', sans-serif; }
  .info-panel p {
    font-size: 20px;
    line-height: 2em;
    text-align: center; }
    .info-panel p span {
      color: #6b6b7e; }

@media (max-width: 600px) {
  .home-container {
    width: 100vw; }
  .home-upper {
    width: 100vw; }
  .component-container {
    width: 100vw;
    left: 100vw; }
  .right {
    transform: translateX(-100vw); }
  .down-arrows {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0; }
  .statement-card {
    margin-bottom: 20%;
    width: 75%;
    font-size: 40px; }
  .flex-container .title-text {
    margin-top: 4.3%; }
    .flex-container .title-text img {
      width: 15%; }
  .short-profile {
    flex-direction: column;
    min-height: 24%; }
    .short-profile figure {
      width: 40%;
      padding-bottom: 40%;
      margin-bottom: 5%;
      margin-top: 2.5%; }
    .short-profile .profile-text {
      font-size: 210%;
      padding: 0;
      width: 100%; }
      .short-profile .profile-text h2 {
        font-size: 80%; }
      .short-profile .profile-text .profile-icons {
        justify-content: center;
        margin-top: 4%; }
        .short-profile .profile-text .profile-icons a {
          width: 30%; }
          .short-profile .profile-text .profile-icons a img {
            padding: 0 20%;
            width: 100%;
            height: auto; }
  .horizontal-divider {
    margin-top: 5%; }
  .about-lower {
    flex-direction: column; }
    .about-lower .info-panel {
      width: 100%; }
      .about-lower .info-panel h3 {
        font-size: 190%; }
      .about-lower .info-panel p {
        font-size: 130%; }
    .about-lower .vertical-divider {
      width: 100%;
      height: 3px;
      background: rgba(128, 128, 128, 0.623); } }
